标题：通用搜索表单
作者：刘康宁
时间：2021.03.09
<template>
  <a-spin :spinning="isLoadingTable" class="max-width" style="min-height: 600px">
    <div id="components-form-demo-advanced-search" style="text-align: center">
      <!-- 筛选查询栏 -->
      <a-form class="ant-advanced-search-form" :form="form" @submit="onSearch">
        <a-row :gutter="24">
          <div v-for="item in formItems" :key="item.key">
            <!-- 展示框 -->
            <a-col :span="24" v-if="item.type === 'show'">
              <a-form-item :label="item.label" style="text-align: left">
                <a-checkable-tag
                  v-for="i in brand"
                  :key="i.brand_product"
                  @change="to_Brand(i.brand_product)"
                  class="brand_product"
                >{{i.brand_product}}</a-checkable-tag>
              </a-form-item>
            </a-col>
            <!-- 普通输入框 -->
            <a-col :span="6" v-if="item.type === 'input'">
              <a-form-item :label="item.label">
                <a-input :placeholder="item.note" v-model="formParams[item.key]" />
              </a-form-item>
            </a-col>
            <!-- 选择器 -->
            <a-col :span="6" v-if="item.type === 'select'">
              <a-form-item :label="item.label">
                <a-select :placeholder="item.note" v-model="formParams[item.key]">
                  <a-select-option
                    v-for="o in item.option"
                    :key="o.value"
                    :value="o.value"
                  >{{ o.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!-- 时间段 -->
            <a-col :span="12" v-if="item.type === 'time'" style="text-align: left">
              <a-form-item :label="item.label" style="overflow:hidden; white-space:nowrap">
                <a-date-picker
                  show-time
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  placeholder="请选择开始日期"
                  v-model="formParams[item.key][0]"
                />
                <span style="padding: 0 10px">~</span>
                <a-date-picker
                  show-time
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  placeholder="请选择结束日期"
                  v-model="formParams[item.key][1]"
                />
              </a-form-item>
            </a-col>
            <!-- 远程搜索 -->
            <a-col :span="6" v-if="item.type === 'remote'">
              <a-form-item :label="item.label">
                <a-select
                  show-search
                  v-model="formParams[item.label]"
                  label-in-value
                  :value="keyword"
                  :placeholder="item.note"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @focus="remoteFetchData('', item.method, item.key)"
                  @search="(value) => { remoteFetchData(value, item.method) }"
                  @change="(value) => { remoteInputChange(value, item.key) }"
                >
                  <a-spin v-if="fetching" style="width: 100%" slot="notFoundContent" size="small" />
                  <a-select-option v-for="d in remoteData" :key="d.value">{{ d.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </div>
        </a-row>
        <a-row>
          <a-col :span="12" :style="{ textAlign: 'left' }">
            <h6
              class="grey"
              style="line-height: 40px; margin-bottom: -10px"
            >在表单中选择筛选条件，点击“查询”按钮列出筛选结果。</h6>
          </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" html-type="submit">查询</a-button>
            <a-button :style="{ marginLeft: '8px' }" @click="resetAndSearch">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
      <!-- 筛选结果栏 -->
      <div class="search-result-list">
        <!-- 此处显示父组件中插入的表格，若父组件没有插入，则显示下方 slot -->
        <slot>暂无信息</slot>
        <!-- 具体用法参照 /views/purchase/comment.vue -->
        <a-pagination
          v-if="!noPagination"
          show-quick-jumper
          show-size-changer
          v-model="current"
          @change="toPage"
          @showSizeChange="onPageSizeChange"
          :total="size * total"
          :pageSize="size"
          :pageSizeOptions="pageSizeOptions ? pageSizeOptions : ['8', '10', '20', '40']"
          style="margin: 20px 0"
        />
      </div>
    </div>
  </a-spin>
</template>

<script>
export default {
  props: {
    defaultPageSize: Number, // 传入的每页条目数
    pageSizeOptions: Array,
    noPagination: Boolean, // 是否显示分页器
    method: String, // 此表单所用接口
    formItems: Array, // 查询筛选选项
    params: Object, // 请求接口时所带参数
    isProducts: Boolean // 是否为商品展示页
  },

  data() {
    return {
      size: 10, // 每页条数
      total: 1, // 总页数
      current: 1, // 当前页码
      isLoadingTable: true, // 表单查询加载中
      fetching: false, // 输入框远程搜索中
      remoteData: [], // 远程输入框搜索获得的数据
      keyword: '', // 远程输入框输入的数据
      formParams: this.resetParams(), // 表单筛选参数
      form: this.$form.createForm(this, { name: 'advanced_search' }),
      brand: [], // 存产品品牌
      flag: 1 // 防止created触底多次请求
    }
  },

  inject: ['reload'], // 注入和methods同级

  created() {
    // console.log(this.$router.currentRoute.path)
    if (this.isProducts) {
      var that = this
      if (that.$router.currentRoute.path === '/products') {
        window.onscroll = function() {
          var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
          var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
          if (scrollTop + windowHeight >= scrollHeight - 300) {
            if (that.total > that.current && that.flag === 1) {
              that.flag = 0
              that.toPage(that.current + 1)
              setTimeout(function() {
                that.flag = 1
              }, 1500)
            } else if (that.total === that.current && that.current !== 1 && that.flag === 1) {
              that.flag = 0
              setTimeout(function() {
                that.flag = 1
              }, 1500)
              if (that.$router.currentRoute.path === '/products') {
                that.$message.info('没有更多商品了')
              }
            }
          }
        }
      }
    }
  },

  methods: {
    // scrollEvent() {
    //   if (document.documentElement.scrollTop + document.documentElement.clientHeight >= document.body.scrollHeight) {
    //     if (this.total > this.current) {
    //       this.toPage(this.current + 1)
    //       console.log('sad')
    //     } else if (this.total === this.current && this.current !== 1) {
    //       this.$message.info('没有更多商品了')
    //     }
    //   }
    // },

    // 远程搜索框查询数据
    remoteFetchData(value, method) {
      this.remoteData = []
      this.fetching = true
      this.get(method, {
        keyword: value
      }).then((res) => {
        this.fetching = false
        this.remoteData = res
      })
    },

    // 远程搜索框数据改变
    remoteInputChange(value, key) {
      this.formParams[key] = value.key
      this.fetching = false
      this.remoteData = []
    },

    // 点击查询
    onSearch(e) {
      e.preventDefault()
      this.toPage(1)
    },

    // 重置筛选表单
    resetParams() {
      this.formParams = Object.assign({}, this.params)
      this.formItems
        .filter((e) => e.type === 'select')
        .map((e) => {
          this.formParams[e.key] = e.default
        })
      this.formItems
        .filter((e) => e.type === 'time')
        .map((e) => {
          this.formParams[e.key] = [null, null]
        })
      return this.formParams
    },

    // 点击重置按钮
    resetAndSearch() {
      this.reload() // 局部刷新
    },

    // 分页栏页码跳转
    toPage(page) {
      const params = this.formParams
      // console.log(params)
      params.number = this.size // 设置每页显示数量
      params.page = params.page === 0 ? 0 : page
      this.isLoadingTable = true
      this.get(this.method, params)
        .then((res) => {
          this.current = page // 更新页面上的页码
          this.total = res.total
          if (this.isProducts) {
            res.data.forEach(e => {
              this.$parent.data.push(e)
            })
            this.$parent.spinning = false
            this.isLoadingTable = false
            this.$emit('ok', res)
          } else {
            this.$parent.data = this.noPagination ? res : res.data
            this.$parent.spinning = false
            this.isLoadingTable = false
            this.$emit('ok', res)
          }
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.isLoadingTable = false
        })
    },

    // 分页栏设置每页条目数
    onPageSizeChange(current, size) {
      this.size = size
      this.toPage(current)
    },

    // 前往品牌
    to_Brand(name) {
      this.formParams.brand_product = name
      this.isLoadingTable = true
      this.get('getAllProductForFranchisee', this.formParams)
        .then((res) => {
          this.$parent.data = this.noPagination ? res : res.data
          this.isLoadingTable = false
        })
        .catch(() => {})
    }
  },

  mounted() {
    // window.addEventListener('scroll', this.scrollEvent, false)
    this.size = this.defaultPageSize || 10
    this.get('getBrandProduct', {
      id_product_category: this.formParams.id_product_category
    })
      .then((res) => {
        this.brand = res
      })
      .catch(() => {})
    this.toPage(1)
  }
  // destroyed() {
  //   window.removeEventListener('scroll', this.scrollEvent, false)
  // }
}
</script>

<style scoped>
.ant-form-item {
  margin-bottom: 5px;
}
.brand_product:hover {
  text-decoration: underline;
}
</style>
