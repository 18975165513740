var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"max-width",staticStyle:{"min-height":"800px"}},[_c('a-row',{staticStyle:{"margin":"20px auto","max-width":"1200px"},attrs:{"type":"flex"}},[_c('a-breadcrumb',[_c('a-breadcrumb-item',[_c('router-link',{attrs:{"to":'/'}},[_c('a-icon',{attrs:{"type":"home"}}),_vm._v("首页 ")],1)],1),_c('a-breadcrumb-item',[_c('router-link',{attrs:{"to":'/user'}},[_vm._v("个人中心")])],1),_c('a-breadcrumb-item',[_c('router-link',{attrs:{"to":'/invoice/apply'}},[_vm._v("申请开票")])],1),_c('a-breadcrumb-item',[_vm._v("申请记录")])],1)],1),_c('CustomForm',{attrs:{"method":"getAllCartForFranchisee","noPagination":"","params":{
      invoice_franchisee_status: 2,
      page: 0,
      status: [8, 14]
    },"formItems":[
      { type: 'input', label: '订单编号', key: 'code', note: '按订单编号精确匹配' },
      { type: 'input', label: '产品名称', key: 'name', note: '按名称模糊匹配' },
      { type: 'select', label: '发票类型', key: 'invoice_type', default: 1, option: [
        { value: 1, label: '增值税专用发票（纸质）' },
        { value: 2, label: '增值税普通发票（电子）' },
        { value: 3, label: '增值税普通发票（纸质）' } ] } ]}},[_c('div',{staticStyle:{"text-align":"left","margin":"20px 0"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.replace('apply')}}},[_c('a-icon',{attrs:{"type":"left"}}),_vm._v("申请开票 ")],1),_c('a-button',{staticStyle:{"margin":"0 15px"},on:{"click":function($event){return _vm.$router.replace('invoice')}}},[_vm._v("已开发票")])],1),_c('div',{staticClass:"search-result-list"},[_c('a-table',{staticStyle:{"margin":"20px"},attrs:{"columns":[
          { title: '订单编号', dataIndex: 'code' },
          { title: '产品名称', dataIndex: 'name', scopedSlots: { customRender: 'name'} },
          { title: '申购时间', dataIndex: 'create_time' },
          { title: '收货时间', dataIndex: 'receive_time' },
          { title: '可开票金额', dataIndex: 'payment_money' },
          { title: '开票状态', dataIndex: 'invoice_franchisee_status', scopedSlots: { customRender: 'invoice_status'}  },
          { title: '发票类型', dataIndex: 'invoice_type',  scopedSlots: { customRender: 'invoice_type'} } ],"row-key":function (record) { return record.id_order_cart; },"data-source":_vm.data,"loading":_vm.spinning,"pagination":false},scopedSlots:_vm._u([{key:"name",fn:function(text, record){return _c('span',{},[_c('router-link',{attrs:{"to":{ name: 'product', query: { sn: record.sn_product_info }}}},[_c('a',{staticClass:"theme"},[_vm._v(_vm._s(text))])])],1)}},{key:"invoice_status",fn:function(text){return _c('span',{staticClass:"yellow"},[_vm._v(_vm._s(_vm.invoice_status[text]))])}},{key:"invoice_type",fn:function(text){return _c('span',{},[_vm._v(_vm._s(_vm.invoice_type[text]))])}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }