标题：发票申请记录
作者：刘康宁
时间：2021.03.08
<template>
  <div class="max-width" style="min-height: 800px">
    <a-row type="flex" style="margin: 20px auto; max-width: 1200px;">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <router-link :to="'/'">
            <a-icon type="home" />首页
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="'/user'">个人中心</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="'/invoice/apply'">申请开票</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>申请记录</a-breadcrumb-item>
      </a-breadcrumb>
    </a-row>
    <CustomForm
      method="getAllCartForFranchisee"
      noPagination
      :params="{
        invoice_franchisee_status: 2,
        page: 0,
        status: [8, 14]
      }"
      :formItems="[
        { type: 'input', label: '订单编号', key: 'code', note: '按订单编号精确匹配' },
        { type: 'input', label: '产品名称', key: 'name', note: '按名称模糊匹配' },
        { type: 'select', label: '发票类型', key: 'invoice_type', default: 1, option: [
          { value: 1, label: '增值税专用发票（纸质）' },
          { value: 2, label: '增值税普通发票（电子）' },
          { value: 3, label: '增值税普通发票（纸质）' },
        ] },
      ]"
    >
      <!-- 操作按钮 -->
      <div style="text-align: left; margin: 20px 0">
        <a-button @click="$router.replace('apply')" type="primary">
          <a-icon type="left"></a-icon>申请开票
        </a-button>
        <a-button style="margin: 0 15px" @click="$router.replace('invoice')">已开发票</a-button>
      </div>
      <!-- 表格 -->
      <div class="search-result-list">
        <a-table
          :columns="[
            { title: '订单编号', dataIndex: 'code' },
            { title: '产品名称', dataIndex: 'name', scopedSlots: { customRender: 'name'} },
            { title: '申购时间', dataIndex: 'create_time' },
            { title: '收货时间', dataIndex: 'receive_time' },
            { title: '可开票金额', dataIndex: 'payment_money' },
            { title: '开票状态', dataIndex: 'invoice_franchisee_status', scopedSlots: { customRender: 'invoice_status'}  },
            { title: '发票类型', dataIndex: 'invoice_type',  scopedSlots: { customRender: 'invoice_type'} },
          ]"
          :row-key="(record) => record.id_order_cart"
          :data-source="data"
          :loading="spinning"
          :pagination="false"
          style="margin: 20px"
        >
          <!-- 产品 -->
          <span slot="name" slot-scope="text, record">
            <router-link :to="{ name: 'product', query: { sn: record.sn_product_info }}">
              <a class="theme">{{ text }}</a>
            </router-link>
          </span>
          <!-- 开票状态 -->
          <span slot="invoice_status" slot-scope="text" class="yellow">{{ invoice_status[text] }}</span>
          <!-- 发票类型 -->
          <span slot="invoice_type" slot-scope="text">{{ invoice_type[text] }}</span>
        </a-table>
      </div>
    </CustomForm>
  </div>
</template>

<script>
import CustomForm from '@/component/CustomForm.vue'
export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: 'advanced_search' }),
      invoice_type: ['不开票', '专票(纸)', '普票(电)', '普票(纸)'],
      invoice_status: ['已删除', '未开票', '已申请', '已开票'],
      spinning: false,
      data: []
    }
  },

  components: {
    CustomForm
  }
}
</script>

<style scoped>
.comment img {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  margin: 0 10px 10px 0;
}
.comment img:nth-last-child(3) {
  margin-right: 0;
}
</style>
